import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp({
  apiKey: "AIzaSyBlikjgTMPlqhRdpvtI81A2pWTR3A9CbyM",
  authDomain: "resident-pmql.firebaseapp.com",
  projectId: "resident-pmql",
  storageBucket: "resident-pmql.appspot.com",
  messagingSenderId: "582363227698",
  appId: "1:582363227698:web:7d79c011f88d8425767e2b",
  measurementId: "G-D0FTC69J3L",
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.

const messaging = getMessaging(firebaseApp);
export default messaging;
